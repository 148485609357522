import React from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ values }) => {
  if (!values || values.isHide) return null

  return (
    <div className={styles.values}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{values.title}</h2>
          <Link to={values.buttonUrl} className={styles.link}>{values.buttonTitle}</Link>
        </div>
        <div className={styles.items}>
          {values.items && values.items.map(value => (
            <div className={styles.item} key={value._key}>
              <div className={styles.itemTop}>
                <p className={styles.head}>{value.title}</p>
                <p className={styles.description}>{value.description}</p>
              </div>
              <div className={styles.box}>
                <img className={styles.image} src={sanityImageUrl(value.image)} alt={value.image?.caption} />
                <ul className={styles.list}>
                  {value.list && value.list.map((item, index) => (
                    <li className={styles.listItem} key={index}>{prepareParagraph(item)}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}