import React from 'react'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ life }) => {
  if (!life || life.isHide) return null

  return (
    <div className={styles.life}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h2 className={styles.title}>{life.title}</h2>
          <p className={styles.description}>{prepareParagraph(life.text)}</p>
        </div>
      </div>
    </div>
  )
}
